import React from "react";
import Navbar from "../assets/images/pages/Navbar";
import Footer from "../assets/images/pages/Footer";
import "../components/Home.css";
import RequestDemo from "../assets/images/pages/RequestDemo"
import { FaHardHat, FaUserTie, FaClipboardList, FaMoneyCheckAlt } from "react-icons/fa";

const HomePage = () => {
    return (
        <div className="homepage">
            {/* Navbar */}
            <Navbar />

            {/* Hero Section */}
            <div className="home-hero">
                
            </div>

            {/* About Section */}
            <div className="about-section">
                <h2>About Us</h2>
                <p>
                    Ambiwo Tech Solutions is a pioneer in HR solutions, offering services across industries. We excel in IT Staffing, HR Consulting, Payroll Services, and Contractual Staffing, making us one of the best HR consulting companies in India.
                </p>
            </div>

            {/* Services Section */}
            <div className="services-section">
                <h2>Our Services</h2>
                <div className="services-cards">
                    <div className="card">
                        <FaHardHat className="card-icon" />
                        <h3>Blue Collar Staffing</h3>
                        <p>
                            Reliable and efficient staffing solutions for skilled and unskilled labor.
                        </p>
                    </div>
                    <div className="card">
                        <FaUserTie className="card-icon" />
                        <h3>White Collar Staffing</h3>
                        <p>
                            Professional and talented workforce tailored to your organizational needs.
                        </p>
                    </div>
                    <div className="card">
                        <FaClipboardList className="card-icon" />
                        <h3>HR Consulting</h3>
                        <p>
                            Expert HR solutions for optimizing workforce and enhancing productivity.
                        </p>
                    </div>
                    <div className="card">
                        <FaMoneyCheckAlt className="card-icon" />
                        <h3>Payroll Services</h3>
                        <p>
                            Hassle-free payroll management for accurate and timely compensation.
                        </p>
                    </div>
                </div>
            </div>

            <RequestDemo />

            {/* Footer */}
            <Footer />
        </div>
    );
};

export default HomePage;
