import React, { useState } from "react";
import Navbar from "./Navbar";
import Footer from "./Footer";
import "./Contact.css";

const Contact = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
    message: "",
  });

  const [successMessage, setSuccessMessage] = useState("");

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log("Form Submitted:", formData);
    setSuccessMessage("Thank you! Your message has been sent.");
    setFormData({ name: "", email: "", phone: "", message: "" }); // Reset form
  };

  return (
    <>
      <Navbar />
      <div className="contact-page">
        <header className="contact-header">
          <h1>Contact Us</h1>
          <p>We’re here to assist you! Reach out for any inquiries or support.</p>
        </header>

        <div className="contact-container">
          <div className="contact-details">
            <h2>Our Contact Details</h2>
            <p><strong>Address:</strong> H, 47, Sector 63 Rd, H Block, Sector 63, Noida, Uttar Pradesh 201301</p>
            <p><strong>Phone:</strong> +91-8287215404</p>
            <p><strong>Email:</strong> hr@ambiwotechsolutions.com</p>
            <p><strong>Working Hours:</strong> Mon - Fri: 9 AM - 6 PM</p>
          </div>

          <div className="contact-form">
            <h2>Send Us a Message</h2>
            <form onSubmit={handleSubmit}>
              <label htmlFor="name">Name</label>
              <input
                type="text"
                id="name"
                name="name"
                value={formData.name}
                onChange={handleChange}
                placeholder="Your Full Name"
                required
              />

              <label htmlFor="email">Email</label>
              <input
                type="email"
                id="email"
                name="email"
                value={formData.email}
                onChange={handleChange}
                placeholder="Your Email Address"
                required
              />

              <label htmlFor="phone">Phone</label>
              <input
                type="tel"
                id="phone"
                name="phone"
                value={formData.phone}
                onChange={handleChange}
                placeholder="Your Phone Number"
                required
              />

              <label htmlFor="message">Message</label>
              <textarea
                id="message"
                name="message"
                rows="5"
                value={formData.message}
                onChange={handleChange}
                placeholder="Your Message"
                required
              ></textarea>

              <button type="submit" className="submit-button">Submit</button>
            </form>
            {successMessage && <p className="success-message">{successMessage}</p>}
          </div>
        </div>

        <div className="map">
          <h2>Visit Our Office</h2>
          <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3502.0315039093643!2d77.37369367495668!3d28.62881788425019!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390ce50a6c1d4a9d%3A0x15e488fd7503f354!2sUsis%20Biz%20Park!5e0!3m2!1sen!2sin!4v1733820537070!5m2!1sen!2sin" 
              width="100%" 
              height="300" 
              style={{border: 0}} 
              allowfullscreen="" 
              loading="lazy" 
              referrerpolicy="no-referrer-when-downgrade"
              ></iframe>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Contact;
