import React from "react";
import "./Footer.css";
import logo from "../Ambiwo_LOGO.png"; // Placeholder for the company logo

const Footer = () => {
    return (
        <footer className="footer">
            <div className="footer-logo">
                <img src={logo} alt="Ambiwo Tech Solutions" />
                <h3>Ambiwo Tech Solutions</h3>
            </div>
            <div className="footer-address">
                <p>Headquarters: Delhi, India</p>
                <p>Email: hr@ambiwotechsolutions.com</p>
                <p>Phone: +91-8287215404</p>
            </div>
        </footer>
    );
};

export default Footer;
