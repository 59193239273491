import React from "react";
import AboutUs from "./assets/images/pages/AboutUs";
import "./"
import Home from "./components/Home"
import ServicesPage from "./components/Service";
import Contact from "./assets/images/pages/Contact";
import Blog from "./assets/images/pages/Blog";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";




function App() {
    return (
        <div>
            
        <Router>
            <Routes>
                <Route exact path="/" element={<Home />} />
                <Route exact path="/about-us" element={<AboutUs />} />
                <Route exact path="/services" element={<ServicesPage />} />
                <Route exact path="/blog" element={<Blog />} />
                <Route exact path="/contact" element={<Contact />} />

            </Routes>
        </Router>
        </div>
    );
}

export default App;
