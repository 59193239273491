import React, { useState } from "react";
import { Link } from "react-router-dom";
import "./Navbar.css";
import logo from "../Ambiwo_LOGO.png"; // Adjust the path if needed

const Navbar = () => {
    const [isMenuOpen, setMenuOpen] = useState(false);

    const toggleMenu = () => setMenuOpen(!isMenuOpen);

    return (
        <nav className="navbar">
            <div className="navbar-container">
                {/* Logo and Brand Name */}
                <div className="logo-brand">
                    <Link to="/" className="logo-link">
                        <img src={logo} alt="Ambiwo Tech Solutions Logo" className="navbar-logo" />
                        <span className="brand-name">Ambiwo Tech Solutions</span>
                    </Link>
                </div>

                {/* Hamburger Menu Icon */}
                <button className="menu-toggle" onClick={toggleMenu} aria-label="Toggle Menu">
                    &#9776;
                </button>

                {/* Navigation Menu */}
                <ul className={`menu ${isMenuOpen ? "active" : ""}`}>
                    <li><Link to="/">Home</Link></li>
                    <li><Link to="/about-us">About Us</Link></li>
                    <li><Link to="/services">Services</Link></li>
                    <li><Link to="/blog">Blog</Link></li>
                    <li><Link to="/contact">Contact</Link></li>
                    
                </ul>
            </div>
        </nav>
    );
};

export default Navbar;
