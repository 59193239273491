import React, { useRef } from "react";
import emailjs from "emailjs-com";
import "./RequestDemo.css";

const RequestDemo = () => {
    const formRef = useRef();

    const sendEmail = (e) => {
        e.preventDefault();

        emailjs
            .sendForm(
                "YOUR_SERVICE_ID", // Replace with your EmailJS service ID
                "YOUR_TEMPLATE_ID", // Replace with your EmailJS template ID
                formRef.current,
                "YOUR_PUBLIC_KEY" // Replace with your EmailJS public key
            )
            .then(
                (result) => {
                    alert("Message sent successfully!");
                },
                (error) => {
                    alert("Failed to send message, please try again later.");
                }
            );

        e.target.reset();
    };

    return (
        <div className="request-demo-wrapper">
            <div className="request-demo-container">
                {/* Left Section - Image with Text Overlay */}
                <div className="demo-left">
                    <div className="overlay-content">
                        <h2>Request a Demo or Quote Now!</h2>
                        <p>Let’s discover the ideal solution for your business.</p>
                    </div>
                </div>

                {/* Right Section - Form */}
                <div className="demo-right">
                    <form className="demo-form" ref={formRef} onSubmit={sendEmail}>
                        <h2>Request a Demo</h2>
                        <div className="form-group">
                            <label htmlFor="name">Name</label>
                            <input type="text" id="name" name="user_name" placeholder="Your Name" required />
                        </div>
                        <div className="form-group">
                            <label htmlFor="email">Email</label>
                            <input type="email" id="email" name="user_email" placeholder="Your Email" required />
                        </div>
                        <div className="form-group">
                            <label htmlFor="mobile">Mobile No.</label>
                            <input type="tel" id="mobile" name="user_mobile" placeholder="Your Mobile Number" required />
                        </div>
                        <div className="form-group">
                            <label htmlFor="company">Company Name</label>
                            <input type="text" id="company" name="user_company" placeholder="Your Company Name" />
                        </div>
                        <div className="form-group">
                            <label htmlFor="services">Services</label>
                            <select id="services" name="user_service">
                                <option value="staffing">Staffing</option>
                                <option value="hr-consulting">HR Consulting Services</option>
                                <option value="other">Other</option>
                            </select>
                        </div>
                        <div className="form-group">
                            <label htmlFor="comments">Comments</label>
                            <textarea id="comments" name="user_comments" placeholder="Additional Information"></textarea>
                        </div>
                        <button type="submit" className="submit-btn">Submit</button>
                    </form>
                </div>
            </div>
        </div>
    );
};

export default RequestDemo;
