import React from "react";
import Navbar from "../pages/Navbar";
import Footer from "../pages/Footer";
import "./AboutUs.css";
import IndiaMap from "../ClientLogo/India.jpg"; // Replace with the actual map image file
import Client1 from "../ClientLogo/Zepto_Logo.svg.png"; // Replace with actual client logos
import Client2 from "../ClientLogo/swiggy-logo.svg";
import Client3 from "../ClientLogo/zomato.jpg";
import Client4 from "../ClientLogo/MilkBasket.png";
import Client5 from "../ClientLogo/JumbotailLogo1.png";
import Client6 from "../ClientLogo/Pharmeasy.png";
import Client7 from "../ClientLogo/infraprime.jpg";
import Client8 from "../ClientLogo/ninjacart.png";
import Client9 from "../ClientLogo/quippo-1280x720.jpg";
import Client10 from "../ClientLogo/wheelocity.jpg";

const AboutUs = () => {
    return (
        <div className="about-us">
            {/* Navbar */}
            <Navbar />

            {/* Hero Section */}
            <div className="hero-section">
                <h1>About AMBIWO Tech Solutions</h1>
            </div>

            {/* About Section */}
            <div className="about-section">
                <h2>Who We Are</h2>
                <p>
                Ambiwo Tech Solutions, is founded by an experienced group of professionals, with a diverse and wide ranging experience across all facets of Human Resource Management.
                We provide tailor made services, from a single expert to support a short-term contract or a permanent vacancy, to a fully managed team of professionals, for a specific project – we can source, recruit and deliver the specialist talent solution to meet your requirements.
                Our consultants combine next-practice vision, practical solutions, and an unparalleled level of commitment and integrity to help our clients translate their talent management and HR strategies into measurable business impact.
                The quality of our service is driven by the need to excel in what we do and to build high value, cost effective solutions for our clients. We do this by having consultants that are experts in their field and who have a full understanding of the business and cultural issues facing their clients.
                Your success is our success and by promoting your company positively it will drive forward the businesses of both our organisations.
                </p>
            </div>

            {/* Map Section */}
            <div className="map-section">
                <h2>Our Presence Across India</h2>
                <img src={IndiaMap} alt="Map of India showing Ambiwo's presence" />
            </div>

            {/* Client Carousel */}
            <div className="client-carousel">
                <h2>Our Clients</h2>
                <div className="carousel">
                    <div className="carousel-track">
                        <img src={Client1} alt="Client 1 Logo" />
                        <img src={Client2} alt="Client 2 Logo" />
                        <img src={Client3} alt="Client 3 Logo" />
                        <img src={Client4} alt="Client 4 Logo" />
                        <img src={Client5} alt="Client 5 Logo" />
                        <img src={Client6} alt="Client 6 Logo" />
                        <img src={Client7} alt="Client 7 Logo" />
                        <img src={Client8} alt="Client 8 Logo" />
                        <img src={Client9} alt="Client 9 Logo" />
                        <img src={Client10} alt="Client 10 Logo" />
                        {/* Repeat to create an infinite loop effect */}
                        <img src={Client1} alt="Client 1 Logo" />
                        <img src={Client2} alt="Client 2 Logo" />
                        <img src={Client3} alt="Client 3 Logo" />
                        <img src={Client4} alt="Client 4 Logo" />
                        <img src={Client5} alt="Client 5 Logo" />
                        <img src={Client6} alt="Client 6 Logo" />
                        <img src={Client7} alt="Client 7 Logo" />
                        <img src={Client8} alt="Client 8 Logo" />
                        <img src={Client9} alt="Client 9 Logo" />
                        <img src={Client10} alt="Client 10 Logo" />
                    </div>
                </div>
            </div>

            {/* Footer */}
            <Footer />
        </div>
    );
};

export default AboutUs;
