import React from "react";
import Navbar from "./Navbar";
import Footer from "./Footer";
import "./Blog.css";

const 
Blog = () => {
  return (
    <>
      <Navbar />
      <div className="blog-page">
        <header className="blog-header">
          <h1>Welcome to Our Blog</h1>
          <p>Insights, tips, and resources to optimize your business operations.</p>
        </header>

        <section className="blog-post">
          <h2>Why Hire an HR Expert Agency?</h2>
          <p>
            In today’s competitive business environment, managing human resources effectively is vital for success.
            Many businesses struggle with complex HR challenges, including recruitment, payroll management, and employee
            compliance. That’s where HR expert agencies come in.
          </p>
          <p>
            By partnering with an HR agency like <strong>Ambiwo Tech Solutions</strong>, you gain access to a pool of
            highly skilled professionals who understand the nuances of workforce management. Whether you're hiring for
            blue-collar or white-collar roles, outsourcing HR services can save time, reduce costs, and enhance overall
            efficiency.
          </p>
          <p>
            Agencies also stay up-to-date with the latest labor laws and compliance requirements, ensuring your business
            avoids legal risks. At Ambiwo, we specialize in delivering end-to-end HR solutions tailored to the unique
            needs of our clients. Let us help you build a stronger, more productive team.
          </p>
        </section>
      </div>
      <Footer />
    </>
  );
};

export default Blog;
