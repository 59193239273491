import React from "react";
import Navbar from "../assets/images/pages/Navbar";
import Footer from "../assets/images/pages/Footer";
import RequestDemo from "../assets/images/pages/RequestDemo";
import "./Service.css";
import coverImage from "../assets/images/ServicesBG.jpg"; // Replace with actual cover image
import blueCollarImg from "../assets/images/Blue-Collar.webp"; // Replace with actual image
import whiteCollarImg from "../assets/images/White-Collar.webp"; // Replace with actual image
import hrConsultingImg from "../assets/images/HR.webp"; // Replace with actual image
import payrollServicesImg from "../assets/images/BlueCollar.png"; // Replace with actual image

const Service = () => {
    const services = [
        {
            id: 1,
            title: "Blue Collar Staffing",
            description: "Efficient and reliable staffing solutions tailored to meet your blue-collar workforce needs.",
            image: blueCollarImg,
        },
        {
            id: 2,
            title: "White Collar Staffing",
            description: "Find the right talent for professional and skilled white-collar roles in your organization.",
            image: whiteCollarImg,
        },
        {
            id: 3,
            title: "HR Consulting",
            description: "Comprehensive HR consulting services to optimize your workforce and processes.",
            image: hrConsultingImg,
        },
        {
            id: 4,
            title: "Payroll Services",
            description: "Accurate, timely, and reliable payroll services to streamline your business operations.",
            image: payrollServicesImg,
        },
    ];

    return (
        <div className="service-page">
            {/* Navbar */}
            <Navbar />

            {/* Cover Section */}
            <div className="service-cover">
                <img src={coverImage} alt="Service Cover" className="service-cover-image" />
                <div className="service-cover-text">
                    <h1>Our Services</h1>
                    <p>
                        Ambiwo Tech Solutions offers a comprehensive range of HR services tailored to meet your
                        unique business requirements.
                    </p>
                </div>
            </div>

            {/* Description Section */}
            <div className="service-description">
                <p>
                    Ambiwo Tech Solutions specializes in end-to-end HR services including Blue Collar Staffing, White
                    Collar Staffing, HR Consulting, and Payroll Services. Partner with us to empower your workforce
                    and streamline business processes.
                </p>
            </div>

            {/* Services Section */}
            <div className="service-card-container">
                {services.map((service) => (
                    <div key={service.id} className="service-card">
                        <img src={service.image} alt={service.title} className="service-card-image" />
                        <h3 className="service-card-title">{service.title}</h3>
                        <p className="service-card-description">{service.description}</p>
                    </div>
                ))}
            </div>

            {/* Request Demo Section */}
            <div className="service-request-demo">
                <RequestDemo />
            </div>

            {/* Footer */}
            <Footer />
        </div>
    );
};

export default Service;
